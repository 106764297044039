export const CountdownTimer = ({ minutes, seconds }) => {
	return (
		<div className="flex justify-center gap-x-1">
			{" "}
			<p> Koden kommer löpa ut om:</p>
			<div className="flex">
				<p>
					{minutes}
					{" minuter"}
				</p>
			</div>
			<div className="flex">
				<p>
					{seconds}
					{" sekunder"}
				</p>
			</div>
		</div>
	)
}
