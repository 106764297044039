import axios, { AxiosRequestConfig } from "axios"
import * as R from "ramda"
import {  commonHeaders } from "consts"


const commonConfig: AxiosRequestConfig = {
	timeout: 30000,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
}

export const createCommonAxiosInstance = (config: AxiosRequestConfig) => createAxiosInstance(R.mergeDeepRight(config, {}) as AxiosRequestConfig)

export const createCommonAxiosInstanceProd = (config: AxiosRequestConfig) => {
	{
    return createAxiosInstance(
		R.mergeDeepRight(config, {
			headers: commonHeaders,
		}) as AxiosRequestConfig
	)}
}

export const createAxiosInstance = (config: AxiosRequestConfig) => {
	const instance = axios.create(R.mergeDeepRight(config, commonConfig) as AxiosRequestConfig)

	instance.interceptors.response.use(
		res => res,
		error => {
			if (error.config) console.warn("Axios error for:", error.config.baseURL, error.config.url)
			else if (error.constructor.name === "Cancel") console.warn("An unknown Axios request was canceled")

			return Promise.reject(error)
		}
	)

	return instance
}
