
interface ICircleArrowDownProps {
  className?: string;
  circleColor?: string;
  fillStroke?: string;
}

const CircleArrowDown = ({ className = "w-4 h-4", circleColor="#ededed", fillStroke = "#000" }: ICircleArrowDownProps) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 16 16">
        <g id="Group_1191" data-name="Group 1191" transform="translate(16 16) rotate(180)">
          <circle id="Ellipse_79" data-name="Ellipse 79" cx="8" cy="8" r="8" fill={`${circleColor}`}/>
          <path id="Path_477" data-name="Path 477" d="M0,0,3.7,3.171,0,6.342" transform="translate(4.829 9.35) rotate(-90)" fill="none" stroke={fillStroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </svg>
    </div>
  )
};

export default CircleArrowDown;
