import { AccountPlanForProject, GenericDataResponse, Project, ProjectListItem } from "models"
import { useQueryClient, useMutation, useQuery } from "react-query"
import { useCommonQueries } from "./useCommonQueries"

type GenericProjects = GenericDataResponse<Project[]>
type GenericProject = GenericDataResponse<Project>
type GenericProjectAccountPlan = GenericDataResponse<AccountPlanForProject>

export const useProjects = () => {
	const { genericQueryFn } = useCommonQueries()

	return useQuery<GenericProjects, Error, Project[]>({
		queryKey: ["project", "all"],
		cacheTime: 1000 * 60,
		staleTime: 1000 * 30,
		queryFn: () => genericQueryFn("useProjects", "project", {}),
		select: data => data.data || [],
	})
}

export const useProject = (eid: string) => {
	const { genericQueryFn } = useCommonQueries()

	return useQuery<GenericProject, Error, Project | undefined>({
		cacheTime: 1000 * 60,
		staleTime: 1000 * 30,
		queryKey: ["project", "single", eid],
		queryFn: () => genericQueryFn("useProjects", "project", { eid }),
		select: data => data.data || undefined,
	})
}

export const useProjectUpdateOrCreate = ({ onSuccess = (res: any) => {}, onError = (err: Error) => {} }) => {
	const client = useQueryClient()
	const { genericMutationFn } = useCommonQueries()

	return useMutation<undefined, Error, Partial<Project>>({
		mutationFn: body => genericMutationFn("useProjectUpdate", "project", body),
		onMutate: async () => {
			await client.cancelQueries(["project", "single"])
		},
		onSettled: async () => {
			await client.invalidateQueries(["project", "all"])
			await client.invalidateQueries(["project", "single"])
		},
		onSuccess: result => {
			onSuccess(result)
		},
		onError: error => {
			onError(error)
		},
	})
}

export const useProjectsSimple = () => {
	const { genericQueryFn } = useCommonQueries()

	return useQuery<ProjectListItem[], Error, ProjectListItem[] | undefined>({
		queryKey: ["project", "all-simple"],
		queryFn: () => genericQueryFn("useProjects", "project", {}, { eid: "*", name: "*", company: "*" }),
		select: data => {
			return (data as any).data || undefined
		},
	})
}

export const useAccountPlanForProject = (eid: string | undefined | null) => {
	const { genericQueryFnProd } = useCommonQueries()

	return useQuery<GenericProjectAccountPlan, Error, AccountPlanForProject | null | undefined>({
		cacheTime: 20 * 60 * 1000,
		staleTime: 20 * 60 * 1000,
		enabled: !!eid,
		queryKey: ["accountPlanForProject", "single", eid],
		queryFn: () =>
			genericQueryFnProd("useAccountPlanForProject", "accountPlanForProject", {
				eid,
			}),
		select: data => data.data || undefined,
	})
}

type ProjectMembers = { roleId: string; userId: string }[]
type GenericProjectMembers = GenericDataResponse<Partial<Project>>

export const useProjectMembers = (projectId: string | undefined | null) => {
	const { genericQueryFn } = useCommonQueries()

	return useQuery<GenericProjectMembers, Error, ProjectMembers>({
		enabled: !!projectId,
		queryKey: ["projectmembers", "single", projectId],
		queryFn: () => genericQueryFn("useAccountPlanForProject", "project", { eid: projectId }),
		select: data => data.data?.members || [],
	})
}

export const useFilteredProjects = (args, fields, sort) => {
	const { genericQueryFn } = useCommonQueries()

	return useQuery<GenericProjects, Error, Project[]>({
		queryKey: ["project", "filtered", args, fields, sort],
		queryFn: () => genericQueryFn("useProjects", "project", args, fields, sort),
		select: data => data.data || [],
	})
}
