import { useEffect, useState } from "react"

interface TimeLeftObject {
	days: number
	hours: number
	minutes: number
	seconds: number
}

export const useCountdown = () => {
	const [countDownDate, setCountDownDate] = useState<Date | undefined>()
	const [timeLeft, setTimeLeft] = useState<TimeLeftObject | undefined>()

	const getTimeLeft = (newCountdown: number): TimeLeftObject => {
		const millisecondsPerDay = 1000 * 60 * 60 * 24
		const millisecondsPerHour = 1000 * 60 * 60
		const millisecondsPerMinute = 1000 * 60
		const millisecondsPerSecond = 1000

		const days = Math.floor(newCountdown / millisecondsPerDay)
		const hours = Math.floor((newCountdown % millisecondsPerDay) / millisecondsPerHour)
		const minutes = Math.floor((newCountdown % millisecondsPerHour) / millisecondsPerMinute)
		const seconds = Math.floor((newCountdown % millisecondsPerMinute) / millisecondsPerSecond)

		return {
			days,
			hours,
			minutes,
			seconds,
		}
	}

	useEffect(() => {
		const updateCountDown = () => {
			if (countDownDate) {
				const newCountdown = countDownDate.getTime() - new Date().getTime()
				setTimeLeft(getTimeLeft(newCountdown))
			}
		}

		const interval = setInterval(updateCountDown, 1000)

		return () => clearInterval(interval)
	}, [countDownDate])

	return { timeLeft, setCountDownDate }
}
