import { WastbyggLogo } from "components/WastbyggLogo"
import { presentationModeProjectIdProps } from "presentationview/calendarUtils"
import { useActivities } from "presentationview/hooks/useActivities"
import { useProject } from "presentationview/hooks/useProjects"
import { format } from "date-fns"
import { CalendarEventLabels } from "../CalendarEventLabels"
import { useEffect, useState } from "react"
import { LCLogo } from "components/LCLogo"
import { RekabLogo } from "components/RekabLogo"

export const CalendarTitleHeader = ({ presentationMode = false, projectId }: presentationModeProjectIdProps) => {
	const { dataUpdatedAt } = useActivities(projectId)
	const project = useProject(projectId)
	const [lastDataUpdateDate, setLastDataUpdateDate] = useState<Date>(new Date())

	const logoForCompany = (companyName: string) => {
		switch (companyName) {
			case "Rekab":
				return <RekabLogo />

			case "LC Denmark":
			case "LC Finland":
			case "LC Norgre":
			case "Logistic Contractor":
				return <LCLogo />

			default:
				return <WastbyggLogo />
		}
	}

	useEffect(() => {
		if (dataUpdatedAt !== 0) setLastDataUpdateDate(new Date(dataUpdatedAt))
	}, [dataUpdatedAt])
	const presentationModeHeader = () => {
		const mins = lastDataUpdateDate.getMinutes() < 10 ? "0" + lastDataUpdateDate.getMinutes() : lastDataUpdateDate.getMinutes()
		const hours = lastDataUpdateDate.getHours() < 10 ? "0" + lastDataUpdateDate.getHours() : lastDataUpdateDate.getHours()

		return (
			<div className={"w-full grid grid-cols-19 "}>
				<div className="flex  items-center col-start-2 col-end-20 justify-between">
					{/* <h1 className=" self-center font-semibold  uppercase text-7xl">{"VECKOSCHEMA"}</h1> */}
					<h1 className=" self-center font-semibold  uppercase text-6xl">{hours + ":" + mins}</h1>

					<CalendarEventLabels />
					{logoForCompany(project.data?.company?.value ?? "")}
				</div>
			</div>
		)
	}

	return <>{presentationMode ? presentationModeHeader() : <></>}</>
}
