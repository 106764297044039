import useMediaQuery from "./useMediaQuery"
import { useCallback } from "react"

export const useScheduleGridLayout = () => {
	const isBelow1024 = useMediaQuery("(max-width: 1024px)")

	const getGridLayout = useCallback(() => {
		return isBelow1024 ? "grid-cols-5" : "grid-cols-7"
	}, [isBelow1024])

	return { isBelow1024, getGridLayout }
}
