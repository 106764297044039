export const RekabLogo = ({ className = "w-6 h-6", bgFill = "#EDEDED" }) => {
	return (
		<svg id="Group_4151" data-name="Group 4151" xmlns="http://www.w3.org/2000/svg" width="441.63" height="74.87" viewBox="0 0 441.63 74.87">
			<defs>
				<clipPath id="clipPath">
					<rect id="Rectangle_2675" data-name="Rectangle 2675" width="441.63" height="74.87" fill="none" />
				</clipPath>
			</defs>
			<g id="Group_4150" data-name="Group 4150" transform="translate(0 0)" clipPath="url(#clipPath)">
				<path
					id="Path_2367"
					data-name="Path 2367"
					d="M35.19,58.59V8.393H73.564V18.6H48.124a1.234,1.234,0,0,0-1.235,1.235V28.1H72.359v9.714H48.124a1.234,1.234,0,0,0-1.235,1.233V48.24H74.841V58.59Z"
					transform="translate(16.533 3.943)"
					fill="#161615"
				/>
				<path
					id="Path_2368"
					data-name="Path 2368"
					d="M99.087,58.59l-16.6-20.57a1.233,1.233,0,0,0-2.193.775v19.8H68.457V8.393H80.3V25.781a1.233,1.233,0,0,0,2.161.814l15.989-18.2H113.62L91.995,31.507,114.9,58.59Z"
					transform="translate(32.163 3.943)"
					fill="#161615"
				/>
				<path
					id="Path_2369"
					data-name="Path 2369"
					d="M141.329,58.59l-3.908-9.855H117.943l-3.7,9.855H101.059l21.079-50.2h11.8L154.8,58.59ZM126.652,24.943l-5.243,14.078h12.645l-5.086-14.066a1.234,1.234,0,0,0-2.316-.012"
					transform="translate(47.48 3.943)"
					fill="#161615"
				/>
				<path
					id="Path_2370"
					data-name="Path 2370"
					d="M182.82,43.985a13.55,13.55,0,0,1-1.63,6.913,12.929,12.929,0,0,1-4.326,4.5,18.855,18.855,0,0,1-6.132,2.446,33.223,33.223,0,0,1-7.055.745H140.6V8.393h23.078a30.711,30.711,0,0,1,5.956.6,17.685,17.685,0,0,1,5.531,2.056,11.705,11.705,0,0,1,4.041,3.935,11.679,11.679,0,0,1,1.559,6.31,10.443,10.443,0,0,1-2.268,6.913,12.64,12.64,0,0,1-5.956,3.935v.143a13.8,13.8,0,0,1,4.005,1.311,11.592,11.592,0,0,1,3.263,2.447,11.46,11.46,0,0,1,2.2,3.473,11.691,11.691,0,0,1,.814,4.467M169.067,22.928a4.022,4.022,0,0,0-1.95-3.723,11.434,11.434,0,0,0-5.778-1.17h-7.5a1.543,1.543,0,0,0-1.542,1.542v8.81h9.608A8.776,8.776,0,0,0,167.187,27a4.741,4.741,0,0,0,1.88-4.076M170.7,42.85a4.432,4.432,0,0,0-2.34-4.324,13.71,13.71,0,0,0-6.239-1.205H152.3V48.806h9.89a18.253,18.253,0,0,0,2.943-.248,9.323,9.323,0,0,0,2.766-.885,5.036,5.036,0,0,0,2.8-4.822"
					transform="translate(66.057 3.943)"
					fill="#161615"
				/>
				<path
					id="Path_2371"
					data-name="Path 2371"
					d="M29.984,58.59,19.7,39.834a2.16,2.16,0,0,0-1.893-1.12H11.842V58.59H0V8.394H22.258a33.175,33.175,0,0,1,7.052.745,18.353,18.353,0,0,1,6.17,2.516,13.227,13.227,0,0,1,4.36,4.68,15.9,15.9,0,0,1,1.63,7.542A12.6,12.6,0,0,1,38.7,32.192a15.087,15.087,0,0,1-7.656,4.964L44.165,58.59Zm-.4-34.5a6.141,6.141,0,0,0-.842-3.185,5.048,5.048,0,0,0-1.892-1.7,8.646,8.646,0,0,0-2.666-.814,19.434,19.434,0,0,0-2.815-.212H13.314a1.541,1.541,0,0,0-1.542,1.542v10.2h8.884a21.259,21.259,0,0,0,3.188-.184,10.113,10.113,0,0,0,2.879-.886,5.158,5.158,0,0,0,2.008-1.845,4.78,4.78,0,0,0,.851-2.916"
					transform="translate(0 3.943)"
					fill="#161615"
				/>
				<rect id="Rectangle_2674" data-name="Rectangle 2674" width="3.307" height="74.87" transform="translate(270.879)" fill="#8c8b8b" />
				<path
					id="Path_2372"
					data-name="Path 2372"
					d="M225.615,12l-3.76,10.2a.419.419,0,0,1-.8-.037L218.319,12h-4.767l-2.741,10.164a.419.419,0,0,1-.8.038L206.255,12H201.1l7.145,17.013h4.27L215.9,17.5h.072l3.384,11.509h4.27L230.771,12Z"
					transform="translate(94.482 5.635)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2373"
					data-name="Path 2373"
					d="M244.192,16.173a.519.519,0,0,1-.716.026,4.613,4.613,0,0,0-2.768-1.158,4.325,4.325,0,0,0-1.962.294,1.43,1.43,0,0,0-.689,2,2.962,2.962,0,0,0,1.63.964c2.33.692,5.018,1.242,6.431,3.482a5.306,5.306,0,0,1-.037,4.662c-2.377,4.345-10.067,3.822-13.195.685l2.456-2.377a.529.529,0,0,1,.728,0,5.729,5.729,0,0,0,3.583,1.476,3.736,3.736,0,0,0,1.876-.447,1.7,1.7,0,0,0,.542-2.346,1.873,1.873,0,0,0-.83-.736,19.426,19.426,0,0,0-2.183-.817c-2.117-.673-4.258-1.058-5.1-2.994a4.985,4.985,0,0,1,1.809-6.044A8.845,8.845,0,0,1,240.7,11.7a8.949,8.949,0,0,1,5.868,2.09l-2.375,2.381"
					transform="translate(109.416 5.496)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2374"
					data-name="Path 2374"
					d="M252.817,16.026V29.008h-4.11V16.026a.523.523,0,0,0-.522-.523h-4.806V12h14.766V15.5h-4.8a.523.523,0,0,0-.523.523"
					transform="translate(114.346 5.635)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2375"
					data-name="Path 2375"
					d="M269.577,24.059a4.6,4.6,0,0,1-.551,2.343,4.406,4.406,0,0,1-1.467,1.526,6.416,6.416,0,0,1-2.078.829,11.316,11.316,0,0,1-2.391.251h-7.821V12h7.821a10.369,10.369,0,0,1,2.02.2,6.011,6.011,0,0,1,1.874.7,3.741,3.741,0,0,1,1.9,3.472,3.536,3.536,0,0,1-.769,2.343,4.28,4.28,0,0,1-2.018,1.333v.049a4.651,4.651,0,0,1,1.358.445,3.837,3.837,0,0,1,2.125,3.52m-4.661-7.137a1.363,1.363,0,0,0-.66-1.261,3.871,3.871,0,0,0-1.959-.4h-2.541a.523.523,0,0,0-.523.523v2.985h3.257a2.971,2.971,0,0,0,1.79-.469,1.609,1.609,0,0,0,.636-1.382m.553,6.752a1.5,1.5,0,0,0-.792-1.465,4.652,4.652,0,0,0-2.115-.409h-3.329v3.892h3.353a6.248,6.248,0,0,0,1-.084,3.138,3.138,0,0,0,.936-.3,1.705,1.705,0,0,0,.949-1.634"
					transform="translate(119.932 5.635)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2376"
					data-name="Path 2376"
					d="M302.628,29.179a15.332,15.332,0,0,1-3.641.409,10.537,10.537,0,0,1-3.76-.65,8.658,8.658,0,0,1-2.956-1.826,8.3,8.3,0,0,1-1.934-2.824,9.306,9.306,0,0,1-.7-3.664,9.315,9.315,0,0,1,.708-3.7,8.242,8.242,0,0,1,1.958-2.824,8.709,8.709,0,0,1,2.932-1.79,10.352,10.352,0,0,1,3.628-.625,11.153,11.153,0,0,1,3.75.613,7.748,7.748,0,0,1,2.812,1.646l-2.6,2.956a4.493,4.493,0,0,0-1.586-1.142,5.393,5.393,0,0,0-2.234-.444,4.946,4.946,0,0,0-1.995.4,4.773,4.773,0,0,0-1.586,1.105,4.972,4.972,0,0,0-1.045,1.681,5.875,5.875,0,0,0-.373,2.127,6.637,6.637,0,0,0,.337,2.162,4.666,4.666,0,0,0,1,1.695,4.543,4.543,0,0,0,1.633,1.105,5.852,5.852,0,0,0,2.222.4,8.332,8.332,0,0,0,1.37-.109,5.408,5.408,0,0,0,.77-.185.626.626,0,0,0,.432-.6V22.947a.521.521,0,0,0-.523-.52h-2.721V19.111h7.017v9.012a13.1,13.1,0,0,1-2.921,1.057"
					transform="translate(136.081 5.489)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2377"
					data-name="Path 2377"
					d="M289.837,29.179a15.332,15.332,0,0,1-3.641.409,10.537,10.537,0,0,1-3.76-.65,8.658,8.658,0,0,1-2.956-1.826,8.3,8.3,0,0,1-1.934-2.824,9.306,9.306,0,0,1-.7-3.664,9.315,9.315,0,0,1,.708-3.7,8.241,8.241,0,0,1,1.958-2.824,8.708,8.708,0,0,1,2.932-1.79,10.352,10.352,0,0,1,3.628-.625,11.153,11.153,0,0,1,3.749.613,7.748,7.748,0,0,1,2.812,1.646l-2.6,2.956a4.493,4.493,0,0,0-1.586-1.142,5.394,5.394,0,0,0-2.234-.444,4.947,4.947,0,0,0-1.995.4,4.774,4.774,0,0,0-1.586,1.105A4.971,4.971,0,0,0,281.6,18.5a5.874,5.874,0,0,0-.373,2.127,6.639,6.639,0,0,0,.337,2.162,4.666,4.666,0,0,0,1,1.695,4.543,4.543,0,0,0,1.633,1.105,5.852,5.852,0,0,0,2.222.4,8.332,8.332,0,0,0,1.37-.109,5.636,5.636,0,0,0,.772-.185.626.626,0,0,0,.431-.6V22.947a.521.521,0,0,0-.523-.52h-2.721V19.111h7.017v9.012a13.1,13.1,0,0,1-2.921,1.057"
					transform="translate(130.071 5.489)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2378"
					data-name="Path 2378"
					d="M233.433,29.009l-1.326-3.341h-6.6l-1.252,3.341h-4.47L226.927,12h4L238,29.009Zm-4.975-11.4-1.777,4.771h4.286l-1.724-4.767a.418.418,0,0,0-.785,0"
					transform="translate(103.26 5.635)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2379"
					data-name="Path 2379"
					d="M223.2,8.391h8.5a.33.33,0,0,1,.344.313v2.647a.33.33,0,0,1-.344.313h-8.5a.329.329,0,0,1-.342-.313V8.7a.329.329,0,0,1,.342-.313"
					transform="translate(104.703 3.942)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2380"
					data-name="Path 2380"
					d="M282.349,12h-4.806l-3.266,5.669a.418.418,0,0,1-.725,0L270.286,12h-4.974l4.168,6.4a11.926,11.926,0,0,1,2.224,7.433v3.185h4.108V25.823a11.986,11.986,0,0,1,2.264-7.418Z"
					transform="translate(124.65 5.635)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2381"
					data-name="Path 2381"
					d="M215.361,44.547a15.32,15.32,0,0,1-3.639.409,10.571,10.571,0,0,1-3.761-.648A8.7,8.7,0,0,1,205,42.48a8.325,8.325,0,0,1-1.934-2.824,10,10,0,0,1,.012-7.365,8.239,8.239,0,0,1,1.959-2.822,8.641,8.641,0,0,1,2.931-1.79,10.149,10.149,0,0,1,3.6-.626,11.364,11.364,0,0,1,3.779.613,7.756,7.756,0,0,1,2.812,1.646l-2.6,2.956a4.492,4.492,0,0,0-1.584-1.141,5.382,5.382,0,0,0-2.236-.445,4.947,4.947,0,0,0-1.995.4,4.81,4.81,0,0,0-1.586,1.105,5.019,5.019,0,0,0-1.045,1.681,5.9,5.9,0,0,0-.373,2.127,6.669,6.669,0,0,0,.337,2.162,4.634,4.634,0,0,0,1,1.693,4.515,4.515,0,0,0,1.634,1.107,5.842,5.842,0,0,0,2.222.4,8.317,8.317,0,0,0,1.368-.107,5.565,5.565,0,0,0,.77-.185.628.628,0,0,0,.432-.6V38.315a.521.521,0,0,0-.523-.52h-2.721V34.479h7.015V43.49a13.034,13.034,0,0,1-2.919,1.057"
					transform="translate(95.081 12.71)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2382"
					data-name="Path 2382"
					d="M225.784,44.376,222.3,38.019a.734.734,0,0,0-.642-.379h-2.021v6.736h-4.013V27.363h7.543a11.293,11.293,0,0,1,2.39.253,6.221,6.221,0,0,1,2.092.852,4.468,4.468,0,0,1,1.477,1.587,5.379,5.379,0,0,1,.553,2.556,4.271,4.271,0,0,1-.936,2.818,5.119,5.119,0,0,1-2.6,1.681l4.446,7.265Zm-.137-11.692a2.078,2.078,0,0,0-.285-1.079,1.716,1.716,0,0,0-.641-.578,2.952,2.952,0,0,0-.9-.275,6.568,6.568,0,0,0-.954-.072h-2.729a.522.522,0,0,0-.523.522v3.457h3.012a7.126,7.126,0,0,0,1.08-.062,3.427,3.427,0,0,0,.976-.3,1.757,1.757,0,0,0,.681-.625,1.626,1.626,0,0,0,.288-.989"
					transform="translate(101.305 12.855)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2383"
					data-name="Path 2383"
					d="M245.028,35.944a9.486,9.486,0,0,1-.7,3.688,8.406,8.406,0,0,1-1.934,2.86,8.183,8.183,0,0,1-2.84,1.839,10.262,10.262,0,0,1-7.179,0,8.2,8.2,0,0,1-2.828-1.839,8.406,8.406,0,0,1-1.934-2.86,9.486,9.486,0,0,1-.7-3.688,9.379,9.379,0,0,1,.7-3.689,8.083,8.083,0,0,1,1.934-2.8,8.169,8.169,0,0,1,2.828-1.778,10.624,10.624,0,0,1,7.179,0,8.364,8.364,0,0,1,4.774,4.577,9.379,9.379,0,0,1,.7,3.689m-4.4,0a5.809,5.809,0,0,0-.309-2.067,4.829,4.829,0,0,0-.944-1.646,4.388,4.388,0,0,0-1.486-1.082,4.643,4.643,0,0,0-1.93-.4,4.477,4.477,0,0,0-4.348,3.123,5.976,5.976,0,0,0-.3,2.067,6.052,6.052,0,0,0,.309,2.125,4.934,4.934,0,0,0,.944,1.671,4.338,4.338,0,0,0,1.474,1.092,4.834,4.834,0,0,0,3.836,0,4.457,4.457,0,0,0,1.486-1.092,4.813,4.813,0,0,0,.955-1.671,6.052,6.052,0,0,0,.309-2.125"
					transform="translate(106.613 12.71)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2384"
					data-name="Path 2384"
					d="M255.887,40.76a6.087,6.087,0,0,1-1.477,2.162,6.788,6.788,0,0,1-2.319,1.405,10.588,10.588,0,0,1-6.466,0,6.553,6.553,0,0,1-2.283-1.405A6.137,6.137,0,0,1,241.9,40.76a7.5,7.5,0,0,1-.5-2.8v-10.6h4.083V37.623a4.72,4.72,0,0,0,.181,1.321,3.381,3.381,0,0,0,.553,1.117,2.581,2.581,0,0,0,.986.782,5.09,5.09,0,0,0,3.376,0,2.7,2.7,0,0,0,1-.782,3.122,3.122,0,0,0,.553-1.117,5.071,5.071,0,0,0,.168-1.321V27.364H256.4v10.6a7.328,7.328,0,0,1-.516,2.8"
					transform="translate(113.415 12.856)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2385"
					data-name="Path 2385"
					d="M268.439,32.6a5.23,5.23,0,0,1-.553,2.51,4.532,4.532,0,0,1-1.49,1.646,6.557,6.557,0,0,1-2.162.913,11.062,11.062,0,0,1-2.546.29h-3.438v6.414h-4.11V27.364h7.643a11.765,11.765,0,0,1,2.607.276,6.152,6.152,0,0,1,2.114.889,4.317,4.317,0,0,1,1.418,1.609,5.318,5.318,0,0,1,.516,2.463m-4.108.024a1.847,1.847,0,0,0-.24-.985,1.68,1.68,0,0,0-.65-.6,2.769,2.769,0,0,0-.926-.29,7.658,7.658,0,0,0-1.069-.072h-2.674a.523.523,0,0,0-.523.523v3.489h3.125a6.249,6.249,0,0,0,1.105-.1,2.984,2.984,0,0,0,.95-.337,1.825,1.825,0,0,0,.661-.638,1.9,1.9,0,0,0,.24-1"
					transform="translate(119.402 12.856)"
					fill="#8c8b8b"
				/>
			</g>
		</svg>
	)
}
