export const getRightColor = (SchemaActivityType: string) => {
	switch (SchemaActivityType) {
		case "Möte":
		case "Möten":
			return "#064c69";
		case "Rond":
		case "Ronder":
			return "#fccf7f";
		case "Besiktning":
		case "Besiktningar":
			return "#189985";
		case "Leverans":
		case "Leveranser":
			return "#b77771";
		case "Maskin":
		case "Maskiner":
			return "#88abb7";
		case "Övrigt":
			return "#646464";
		default:
			return "#646464";
	}
};
