import { SchemaActivityType, SchemaActivityStateValue, SchemaActivity } from "models"
import { ActivityStatus } from "presentationview/ActivityStatus"
import { getRightColor } from "presentationview/schemaActivity"
import { useRouteMatch, Link } from "react-router-dom"

const statusIcon = (endDate: string, schemaActivityType: SchemaActivityType, value: SchemaActivityStateValue) => {
	if (value === "DONE") {
		return <ActivityStatus value={"DONE"} className=" w-4 h-4 mr-1 mt-0.5" />
	}
	return <div></div>
	// const isPastEndDate = new Date() > new Date(endDate)

	// if (schemaActivityType === "Övrigt" || schemaActivityType === "Maskin" || (!isPastEndDate && value !== "DONE")) {
	// 	return <ActivityStatus value={"NOT_DONE"} className=" w-4 h-4 mr-1 mt-0.5" />
	// }

	// if (value === "DONE" || !isPastEndDate) {
	// 	return <ActivityStatus value={"DONE"} className=" w-4 h-4 mr-1 mt-0.5" />
	// }

	// return <ActivityStatus value={"LATE"} className=" w-4 h-4 mr-1 mt-0.5" />
}
interface IActivityCardProps {
	data: SchemaActivity
	projectId: string
}
export const OneDayActivityCard = ({ data, projectId }: IActivityCardProps) => {
	const { url } = useRouteMatch()
	return (
		<div
			className={" bg-white h-4 border-l-8 w-full text-sm rounded  cursor-pointer "}
			style={{
				borderColor: getRightColor(data.schemaActivityType),
			}}>
			<Link to={`${url}/edit/${data.eid}`}>
				<div className={"grid grid-cols-7 justify-between w-full pl-2"}>
					<div className={"flex col-start-1 col-end-7 text-left max-h-5"}>
						<p className="font-light text-xs">{data.startsAt}</p>
						<p className={"schema-acticvity-title text-xs"}>{data.title}</p>
					</div>
					<div className="col-start-7 col-end-8 justify-self-end">{statusIcon(data.endDate, data.schemaActivityType, data.value ?? "NOT_RELEVANT")}</div>
				</div>
			</Link>
		</div>
	)
}
