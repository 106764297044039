import { commonHeaders } from "consts"
import { createCommonAxiosInstance, createCommonAxiosInstanceProd } from "presentationview/axiosInstances"

const baseURL = process.env.REACT_APP_BACKEND_URL
const baseURLProd = process.env.REACT_APP_PROD_BACKEND_URL
const axiosInstance = createCommonAxiosInstance({ baseURL })
const axiosInstanceProd = createCommonAxiosInstanceProd({
	baseURL: baseURLProd,
})

export const useCommonQueries = () => {
	const dqlAuthHeaders = commonHeaders

	const genericQueryFn = (name: string, collection: string, args: any, fields?: any, sort?: any) => {
		return axiosInstance
			.post<any>(
				`/dql?${name}`,
				{
					tenant: process.env.REACT_APP_TENANT,
					query: { [collection]: { arguments: { ...args }, fields: fields, sort } },
				},
				{ headers: { ...dqlAuthHeaders } }
			)
			.then(res => res.data)
			.catch(error => {
				throw error
			})
	}

	const genericQueryFnProd = (name: string, collection: string, args: any, fields?: any) =>
		axiosInstanceProd
			.post<any>(`/dql?${name}`, {
				tenant: process.env.REACT_APP_PROD_TENANT,
				query: { [collection]: { arguments: { ...args }, fields: fields } },
			})
			.then(res => res.data)
			.catch(error => {
				throw error
			})

	const genericMutationFn = (name: string, collection: string, data: any) =>
		axiosInstance
			.post<any>(
				`/dql?${name}`,
				{
					tenant: process.env.REACT_APP_TENANT,
					mutation: { [collection]: { ...data } },
				},
				{ headers: { ...dqlAuthHeaders } }
			)
			.then(res => res.data)
			.catch(error => {
				throw error
			})

	const genericMutationManyFn = (name: string, collection: string, data: any[]) =>
		axiosInstance
			.post<any>(
				`/dql?${name}`,
				{
					tenant: process.env.REACT_APP_TENANT,
					mutation: { [collection]: [...data] },
				},
				{ headers: { ...dqlAuthHeaders } }
			)
			.then(res => res.data)
			.catch(error => {
				throw error
			})

	return {
		genericQueryFn,
		genericMutationManyFn,
		genericMutationFn,
		genericQueryFnProd,
	}
}
