export const LCLogo = ({ className = "w-6 h-6", bgFill = "#EDEDED" }) => {
	return (
		<svg id="Group_4148" data-name="Group 4148" xmlns="http://www.w3.org/2000/svg" width="282.948" height="74.875" viewBox="0 0 282.948 74.875">
			<defs>
				<clipPath id="clipPath">
					<rect id="Rectangle_2667" data-name="Rectangle 2667" width="282.948" height="74.875" fill="none" />
				</clipPath>
			</defs>
			<g id="Group_4147" data-name="Group 4147" transform="translate(0 0)" clipPath="url(#clipPath)">
				<path id="Path_2351" data-name="Path 2351" d="M0,58.594V8.393H12.2V46.18a1.851,1.851,0,0,0,1.851,1.851H42.683V58.594Z" transform="translate(0 3.943)" fill="#161615" />
				<path
					id="Path_2352"
					data-name="Path 2352"
					d="M68.776,21.632a1.861,1.861,0,0,1-2.442.007,13.945,13.945,0,0,0-3.362-2.074,14.408,14.408,0,0,0-5.85-1.169,13.943,13.943,0,0,0-10.35,4.36,13.774,13.774,0,0,0-2.941,4.858,17.7,17.7,0,0,0-1.03,6.1,17.932,17.932,0,0,0,1.064,6.272,14.866,14.866,0,0,0,2.941,4.929,13.432,13.432,0,0,0,4.5,3.225,15.028,15.028,0,0,0,11.627,0,13.951,13.951,0,0,0,3.369-2.108,1.862,1.862,0,0,1,2.437,0l7.985,7.007a25.416,25.416,0,0,1-8.686,5.424,30.475,30.475,0,0,1-10.92,1.912,30.175,30.175,0,0,1-10.882-1.912,25.474,25.474,0,0,1-8.65-5.424,24.793,24.793,0,0,1-5.708-8.439,28.006,28.006,0,0,1-2.055-10.882,27.674,27.674,0,0,1,2.055-10.885,23.854,23.854,0,0,1,5.708-8.261,25.41,25.41,0,0,1,8.65-5.246A31.208,31.208,0,0,1,57.123,7.478a31.52,31.52,0,0,1,10.92,1.843,25.358,25.358,0,0,1,8.686,5.246Z"
					transform="translate(14.017 3.513)"
					fill="#161615"
				/>
				<rect id="Rectangle_2666" data-name="Rectangle 2666" width="3.307" height="74.875" transform="translate(112.186)" fill="#8c8b8b" />
				<path
					id="Path_2353"
					data-name="Path 2353"
					d="M117.645,11.995l-3.76,10.2a.419.419,0,0,1-.8-.037l-2.74-10.164h-4.767L102.84,22.159a.418.418,0,0,1-.8.038l-3.762-10.2H93.128l7.145,17.014h4.27L107.929,17.5H108l3.384,11.509h4.27L122.8,11.995Z"
					transform="translate(43.763 5.636)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2354"
					data-name="Path 2354"
					d="M136.22,16.173a.519.519,0,0,1-.716.026,4.613,4.613,0,0,0-2.768-1.158,4.325,4.325,0,0,0-1.962.294,1.43,1.43,0,0,0-.689,2,2.962,2.962,0,0,0,1.63.964c2.33.692,5.018,1.242,6.431,3.482a5.306,5.306,0,0,1-.037,4.663c-2.377,4.345-10.067,3.822-13.2.685l2.456-2.377a.529.529,0,0,1,.728,0,5.729,5.729,0,0,0,3.584,1.476,3.736,3.736,0,0,0,1.876-.447,1.7,1.7,0,0,0,.542-2.346,1.873,1.873,0,0,0-.831-.736,19.426,19.426,0,0,0-2.183-.817c-2.117-.673-4.258-1.058-5.105-2.994a4.985,4.985,0,0,1,1.809-6.044,8.846,8.846,0,0,1,4.937-1.145,8.95,8.95,0,0,1,5.868,2.09l-2.375,2.381"
					transform="translate(58.699 5.497)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2355"
					data-name="Path 2355"
					d="M144.844,16.027V29.009h-4.11V16.027a.523.523,0,0,0-.522-.523h-4.807V12h14.767V15.5h-4.805a.524.524,0,0,0-.523.523"
					transform="translate(63.63 5.637)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2356"
					data-name="Path 2356"
					d="M161.606,24.06a4.6,4.6,0,0,1-.551,2.343,4.407,4.407,0,0,1-1.467,1.526,6.416,6.416,0,0,1-2.078.829,11.317,11.317,0,0,1-2.392.251H147.3V12h7.821a10.369,10.369,0,0,1,2.02.2,6.011,6.011,0,0,1,1.874.7,3.742,3.742,0,0,1,1.9,3.472,3.536,3.536,0,0,1-.769,2.343,4.28,4.28,0,0,1-2.018,1.333v.049a4.651,4.651,0,0,1,1.358.445,3.838,3.838,0,0,1,2.126,3.52m-4.661-7.138a1.364,1.364,0,0,0-.66-1.261,3.871,3.871,0,0,0-1.959-.4h-2.541a.524.524,0,0,0-.523.523v2.985h3.257a2.971,2.971,0,0,0,1.79-.469,1.609,1.609,0,0,0,.636-1.382m.553,6.753a1.5,1.5,0,0,0-.792-1.466,4.652,4.652,0,0,0-2.115-.409H151.26v3.892h3.353a6.249,6.249,0,0,0,1-.084,3.138,3.138,0,0,0,.936-.3,1.705,1.705,0,0,0,.95-1.635"
					transform="translate(69.217 5.637)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2357"
					data-name="Path 2357"
					d="M194.657,29.18a15.333,15.333,0,0,1-3.641.409,10.537,10.537,0,0,1-3.76-.65,8.659,8.659,0,0,1-2.956-1.826,8.3,8.3,0,0,1-1.934-2.824,9.305,9.305,0,0,1-.7-3.665,9.315,9.315,0,0,1,.709-3.7,8.242,8.242,0,0,1,1.958-2.824,8.708,8.708,0,0,1,2.932-1.79,10.355,10.355,0,0,1,3.629-.625,11.151,11.151,0,0,1,3.748.613,7.748,7.748,0,0,1,2.812,1.646l-2.6,2.956a4.493,4.493,0,0,0-1.586-1.142,5.394,5.394,0,0,0-2.234-.444,4.947,4.947,0,0,0-1.995.4,4.774,4.774,0,0,0-1.586,1.105,4.972,4.972,0,0,0-1.045,1.682,5.875,5.875,0,0,0-.373,2.127,6.638,6.638,0,0,0,.337,2.162,4.666,4.666,0,0,0,1,1.695,4.543,4.543,0,0,0,1.633,1.105,5.852,5.852,0,0,0,2.223.4,8.332,8.332,0,0,0,1.37-.109,5.41,5.41,0,0,0,.77-.185.626.626,0,0,0,.432-.6V22.948a.521.521,0,0,0-.523-.52h-2.721V19.111h7.017v9.012a13.1,13.1,0,0,1-2.921,1.057"
					transform="translate(85.37 5.49)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2358"
					data-name="Path 2358"
					d="M181.865,29.18a15.333,15.333,0,0,1-3.641.409,10.537,10.537,0,0,1-3.76-.65,8.659,8.659,0,0,1-2.956-1.826,8.3,8.3,0,0,1-1.934-2.824,9.306,9.306,0,0,1-.7-3.665,9.316,9.316,0,0,1,.708-3.7,8.242,8.242,0,0,1,1.958-2.824,8.709,8.709,0,0,1,2.932-1.79,10.355,10.355,0,0,1,3.629-.625,11.151,11.151,0,0,1,3.748.613,7.748,7.748,0,0,1,2.812,1.646l-2.6,2.956a4.493,4.493,0,0,0-1.586-1.142,5.394,5.394,0,0,0-2.234-.444,4.947,4.947,0,0,0-1.995.4,4.773,4.773,0,0,0-1.586,1.105,4.971,4.971,0,0,0-1.045,1.682,5.875,5.875,0,0,0-.373,2.127,6.638,6.638,0,0,0,.337,2.162,4.666,4.666,0,0,0,1,1.695,4.543,4.543,0,0,0,1.633,1.105,5.852,5.852,0,0,0,2.223.4,8.333,8.333,0,0,0,1.37-.109,5.638,5.638,0,0,0,.772-.185.626.626,0,0,0,.431-.6V22.948a.521.521,0,0,0-.523-.52h-2.721V19.111h7.017v9.012a13.1,13.1,0,0,1-2.921,1.057"
					transform="translate(79.358 5.49)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2359"
					data-name="Path 2359"
					d="M125.461,29.01l-1.326-3.341h-6.6l-1.252,3.341h-4.47L118.955,12h4l7.072,17.014Zm-4.976-11.405-1.777,4.771h4.286L121.27,17.61a.418.418,0,0,0-.785,0"
					transform="translate(52.542 5.637)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2360"
					data-name="Path 2360"
					d="M115.225,8.391h8.5a.33.33,0,0,1,.344.313v2.647a.33.33,0,0,1-.344.313h-8.5a.329.329,0,0,1-.342-.313V8.7a.329.329,0,0,1,.342-.313"
					transform="translate(53.986 3.942)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2361"
					data-name="Path 2361"
					d="M174.377,11.995H169.57L166.3,17.664a.418.418,0,0,1-.725,0l-3.266-5.669h-4.974l4.169,6.4a11.927,11.927,0,0,1,2.224,7.433v3.185h4.108V25.824a11.986,11.986,0,0,1,2.264-7.419Z"
					transform="translate(73.937 5.636)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2362"
					data-name="Path 2362"
					d="M107.39,44.548a15.32,15.32,0,0,1-3.64.409,10.572,10.572,0,0,1-3.762-.648,8.7,8.7,0,0,1-2.956-1.827A8.325,8.325,0,0,1,95.1,39.658a10,10,0,0,1,.012-7.366A8.239,8.239,0,0,1,97.07,29.47,8.641,8.641,0,0,1,100,27.679a10.15,10.15,0,0,1,3.6-.626,11.365,11.365,0,0,1,3.779.613,7.737,7.737,0,0,1,2.81,1.646l-2.594,2.956a4.493,4.493,0,0,0-1.585-1.141,5.383,5.383,0,0,0-2.236-.445,4.947,4.947,0,0,0-1.995.4,4.81,4.81,0,0,0-1.586,1.105,5.018,5.018,0,0,0-1.045,1.682,5.9,5.9,0,0,0-.373,2.127,6.671,6.671,0,0,0,.337,2.162,4.635,4.635,0,0,0,1,1.693,4.515,4.515,0,0,0,1.635,1.107,5.842,5.842,0,0,0,2.223.4,8.318,8.318,0,0,0,1.368-.107,5.616,5.616,0,0,0,.77-.185.628.628,0,0,0,.432-.6V38.316a.521.521,0,0,0-.523-.52h-2.721V34.479h7.016v9.012a13.036,13.036,0,0,1-2.919,1.057"
					transform="translate(44.361 12.712)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2363"
					data-name="Path 2363"
					d="M117.813,44.377l-3.487-6.357a.734.734,0,0,0-.642-.379h-2.021v6.737H107.65V27.363h7.544a11.294,11.294,0,0,1,2.39.253,6.221,6.221,0,0,1,2.092.853,4.468,4.468,0,0,1,1.477,1.588,5.379,5.379,0,0,1,.553,2.556,4.271,4.271,0,0,1-.936,2.818,5.12,5.12,0,0,1-2.6,1.682l4.447,7.266Zm-.137-11.693a2.078,2.078,0,0,0-.285-1.079,1.716,1.716,0,0,0-.641-.578,2.953,2.953,0,0,0-.9-.275,6.568,6.568,0,0,0-.954-.072h-2.73a.522.522,0,0,0-.523.522V34.66h3.012a7.127,7.127,0,0,0,1.08-.062,3.427,3.427,0,0,0,.976-.3,1.757,1.757,0,0,0,.681-.625,1.626,1.626,0,0,0,.288-.989"
					transform="translate(50.587 12.858)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2364"
					data-name="Path 2364"
					d="M137.056,35.945a9.487,9.487,0,0,1-.7,3.688,8.407,8.407,0,0,1-1.934,2.86,8.184,8.184,0,0,1-2.84,1.839,10.262,10.262,0,0,1-7.179,0,8.2,8.2,0,0,1-2.828-1.839,8.406,8.406,0,0,1-1.934-2.86,9.486,9.486,0,0,1-.7-3.688,9.38,9.38,0,0,1,.7-3.689,8.083,8.083,0,0,1,1.934-2.8,8.17,8.17,0,0,1,2.828-1.779,10.625,10.625,0,0,1,7.179,0,8.364,8.364,0,0,1,4.774,4.577,9.38,9.38,0,0,1,.7,3.689m-4.4,0a5.808,5.808,0,0,0-.309-2.067,4.83,4.83,0,0,0-.944-1.646,4.388,4.388,0,0,0-1.486-1.082,4.643,4.643,0,0,0-1.93-.4,4.477,4.477,0,0,0-4.348,3.124,5.976,5.976,0,0,0-.3,2.067,6.052,6.052,0,0,0,.309,2.126,4.934,4.934,0,0,0,.944,1.671,4.339,4.339,0,0,0,1.474,1.092,4.834,4.834,0,0,0,3.836,0,4.457,4.457,0,0,0,1.486-1.092,4.813,4.813,0,0,0,.955-1.671,6.052,6.052,0,0,0,.309-2.126"
					transform="translate(55.895 12.712)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2365"
					data-name="Path 2365"
					d="M147.914,40.761a6.087,6.087,0,0,1-1.477,2.162,6.788,6.788,0,0,1-2.319,1.405,10.589,10.589,0,0,1-6.466,0,6.554,6.554,0,0,1-2.283-1.405,6.137,6.137,0,0,1-1.442-2.162,7.5,7.5,0,0,1-.5-2.8v-10.6h4.083v10.26a4.721,4.721,0,0,0,.181,1.321,3.382,3.382,0,0,0,.553,1.117,2.582,2.582,0,0,0,.986.782,5.09,5.09,0,0,0,3.376,0,2.7,2.7,0,0,0,1-.782,3.122,3.122,0,0,0,.553-1.117,5.07,5.07,0,0,0,.168-1.321V27.364h4.108v10.6a7.328,7.328,0,0,1-.516,2.8"
					transform="translate(62.698 12.858)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_2366"
					data-name="Path 2366"
					d="M160.467,32.6a5.231,5.231,0,0,1-.553,2.511,4.546,4.546,0,0,1-1.49,1.646,6.557,6.557,0,0,1-2.162.913,11.062,11.062,0,0,1-2.546.29h-3.438v6.415h-4.11V27.364h7.644a11.765,11.765,0,0,1,2.608.276,6.153,6.153,0,0,1,2.114.889,4.317,4.317,0,0,1,1.418,1.61,5.318,5.318,0,0,1,.516,2.464m-4.108.024a1.847,1.847,0,0,0-.24-.985,1.68,1.68,0,0,0-.65-.6,2.769,2.769,0,0,0-.926-.29,7.659,7.659,0,0,0-1.069-.072H150.8a.523.523,0,0,0-.523.523v3.49H153.4a6.251,6.251,0,0,0,1.105-.1,2.984,2.984,0,0,0,.95-.337,1.825,1.825,0,0,0,.661-.638,1.9,1.9,0,0,0,.24-1"
					transform="translate(68.687 12.858)"
					fill="#8c8b8b"
				/>
			</g>
		</svg>
	)
}
