import { commonHeaders, header } from "consts"
import React, { useState, useEffect } from "react"
import { useCountdown } from "./useCountdown"
import { useLocalStorage } from "./useLocalStorage"

interface document {
	Id: string
	Permissions: string[]
}

interface permissionTypes {
	type: string
	permissions: string[]
}
interface permissions {
	documents: document[]
	types: permissionTypes
}
interface tokenResponseData {
	token: string
	permissions: permissions
}

export const useToken = () => {
	const [tvCode, setTvCode] = useState<string>("")
	const [token, setToken] = useLocalStorage("token", "")
	const [projectId, setProjectId] = useLocalStorage<string>("projectId", "")

	const diff = 15
	const { timeLeft, setCountDownDate } = useCountdown()

	const setNewCountdown = () => {
		setCountDownDate(new Date(new Date().getTime() + diff * 60 * 1000))
	}

	const getNewTVCode = async () => {
		try {
			const tvCodeResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/api/token/giveMeCode`, { method: "GET", headers: header })
			if (tvCodeResponse.status === 200) {
				const newTvCode = (await tvCodeResponse.json()).code
				setTvCode(newTvCode)
				setNewCountdown()
			}
		} catch (error) {
			console.error("Failed to fetch TV code:", error)
		}
	}

	useEffect(() => {
		getNewTVCode()
		const interval = setInterval(getNewTVCode, diff * 60 * 1000)
		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		commonHeaders["xxx-api-token"] = token
	}, [token])

	useEffect(() => {
		const getTokenAndProjectId = async () => {
			if (!tvCode) return

			const url = `${process.env.REACT_APP_BACKEND_URL}/auth/api/token/giveMeToken?code=${tvCode}`
			const tokenResponse = await fetch(url, { method: "GET", headers: header })

			if (tokenResponse.status !== 200) {
				console.warn("status", tokenResponse.status)
			} else {
				const data = (await tokenResponse.json()) as tokenResponseData
				const projectDocument = data.permissions.documents.find(doc => doc.Id.includes("Project/"))

				setToken(data.token)

				if (projectDocument) {
					const newProjectId = projectDocument.Id.replace("Project/", "")
					setProjectId(newProjectId)
				}
			}
		}

		getTokenAndProjectId()
		const interval = setInterval(getTokenAndProjectId, 10000)
		return () => clearInterval(interval)
	}, [tvCode, setToken, setProjectId])

	return { tvCode, token, projectId, timeLeft }
}
