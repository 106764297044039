import { useCallback, useMemo } from "react"
import * as R from "ramda"
import { startOfWeek } from "date-fns"
import { endOfWeek } from "date-fns/esm"
import { SchemaActivity } from "models"

import { useActivities } from "./useActivities"
import { IsStartDayAndEndDayTheSame } from "presentationview/calendarUtils"
function removeTime(date = new Date()) {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}
export const useScheduleActivities = (projectId: string) => {
	const { data } = useActivities(projectId)
	const sortedData = useMemo(() => {
		if (!data) {
			return []
		}
		return data.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
	}, [data])

	const findOneDayAndMultipleDayEventsForDate = useCallback(
		(day: Date) => {
			if (!data) {
				return []
			}
			const stripedDay = removeTime(day)
			const matches = data?.filter((activity: SchemaActivity) => stripedDay >= removeTime(new Date(activity.startDate)) && stripedDay <= removeTime(new Date(activity.endDate)))
			matches.sort((a, b) => (a.startsAt > b.startsAt ? 1 : -1))
			const [oneDayActivites, activitesOverMultipleDays] = R.partition(IsStartDayAndEndDayTheSame, matches)

			const weekStart = startOfWeek(day, { weekStartsOn: 1 })
			const weekEnd = endOfWeek(day, { weekStartsOn: 1 })
			const weekMultiDayActivites = data?.filter(
				(activity: SchemaActivity) =>
					!IsStartDayAndEndDayTheSame(activity) && weekStart.getTime() < removeTime(new Date(activity.endDate)).getTime() && weekEnd.getTime() > removeTime(new Date(activity.startDate)).getTime()
			)
			const filterd = weekMultiDayActivites.filter((a, index) => new Date(a.startDate) <= day && new Date(a.endDate) <= day)
			return [oneDayActivites, [...filterd, ...activitesOverMultipleDays]]
		},
		[data]
	)

	const findActivityForDate = useCallback(
		(day: Date) => {
			if (!data) {
				return []
			}
			const stripedDay = removeTime(day)
			const matches = data?.filter((activity: SchemaActivity) => stripedDay >= removeTime(new Date(activity.startDate)) && stripedDay <= removeTime(new Date(activity.endDate)))
			matches.sort((a, b) => (a.startsAt > b.startsAt ? 1 : -1))
			return matches
		},
		[data]
	)

	return {
		data,
		sortedData,
		findActivityForDate,
		findOneDayAndMultipleDayEventsForDate,
	}
}
