import { useState, useCallback, useEffect } from "react"
import { getDaysInMonth, addDays, startOfMonth, startOfWeek, differenceInCalendarDays, differenceInDays, isMonday, getISOWeek, subMonths, addMonths, endOfWeek } from "date-fns"

const getMonthRange = (d = new Date()) => {
	const dates = [...Array(getDaysInMonth(d))].map((_, idx) => addDays(startOfMonth(d), idx))
	const weekStart = startOfWeek(dates[0], { weekStartsOn: 1 })
	const firstWeekOfMonth = [...Array(differenceInDays(dates[0], weekStart))].map((_, idx) => addDays(weekStart, idx))
	const lastWeekStart = startOfWeek(dates[dates.length - 1], {
		weekStartsOn: 1,
	})
	const lastWeekOfMonth = [...Array(7)].map((_, idx) => addDays(lastWeekStart, idx))

	const lastDayInMonth = dates[dates.length - 1]
	const endOfRange = addDays(endOfWeek(lastDayInMonth), 1)
	const lastWeekPartialLength = differenceInCalendarDays(endOfRange, lastDayInMonth)
	const lastWeekPartial = [...Array(lastWeekPartialLength)].map((_, idx) => addDays(lastDayInMonth, idx + 1))

	const output = [...firstWeekOfMonth, ...dates, ...lastWeekPartial]
	return output.length < 40 ? [...output, ...[...Array(7)].map((_, idx) => addDays(lastWeekOfMonth[lastWeekOfMonth.length - 1], idx + 1))] : output
}
// returns previous week and current + 3 in future
const getWeeklyScheduleRange = (today = new Date()) => {
	const firstDayOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 })
	const lastDayOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1 })
	const startOfPreviousWeek = new Date(firstDayOfCurrentWeek.getFullYear(), firstDayOfCurrentWeek.getMonth(), firstDayOfCurrentWeek.getDate() - 7)
	const endOfWeek3WeeksAhead = new Date(lastDayOfCurrentWeek.getFullYear(), lastDayOfCurrentWeek.getMonth(), lastDayOfCurrentWeek.getDate() + 21)
	const allDates = [...Array(differenceInDays(endOfWeek3WeeksAhead, startOfPreviousWeek) + 1)].map((_, idx) => addDays(startOfPreviousWeek, idx))
	return allDates
}

const getWeekNumbers = (data = []) => {
	if (!data.length) return []

	const mondays = [...new Set(data.map(x => isMonday(x) && x).filter(x => Boolean(x)))]

	return mondays.map(m => getISOWeek(m))
}
const convertToGroupedAsWeek = arr => {
	return arr.reduce(function (rows, key, index) {
		return (index % 7 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows
	}, [])
}

export const useCalendar = () => {
	const [currentDate, setCurrentDate] = useState(new Date())
	const [weeklyScheduleDays] = useState(getWeeklyScheduleRange(currentDate))
	const [scheduleDaysAsWeek] = useState(convertToGroupedAsWeek(getWeeklyScheduleRange(currentDate)))
	const [weeklyScheduleWeekNumbers] = useState(getWeeklyScheduleRange(currentDate))

	const [monthlyScheduleDays, setMonthlyScheduleDays] = useState([])
	const [monthlyScheduleWeekNumbers, setMonthlyScheduleWeekNumbers] = useState([])
	const [selectedDate, setSelectedDate] = useState(new Date())
	const [loading, setLoading] = useState(false)

	const handleSelectDate = useCallback(date => {
		setSelectedDate(date)
	}, [])

	const handleGoNextMonth = useCallback(() => {
		if (!currentDate) return

		setLoading(true)
		const nextMonth = addMonths(currentDate, 1)

		const _t = setTimeout(() => {
			setCurrentDate(nextMonth)

			setLoading(false)

			clearTimeout(_t)
		}, 500)
	}, [currentDate])

	const handleGoPreviousMonth = useCallback(() => {
		if (!currentDate) return

		setLoading(true)
		const previousMonth = subMonths(currentDate, 1)

		const _t = setTimeout(() => {
			setCurrentDate(previousMonth)

			setLoading(false)

			clearTimeout(_t)
		}, 500)
	}, [currentDate])

	useEffect(() => {
		setMonthlyScheduleDays(getMonthRange(currentDate))
		setMonthlyScheduleWeekNumbers(getWeekNumbers(getMonthRange(currentDate)))
	}, [currentDate])

	return {
		currentDate,
		weeklyScheduleDays,
		weeklyScheduleWeekNumbers,
		monthlyScheduleDays,
		monthlyScheduleWeekNumbers,
		loading,
		selectedDate,
		scheduleDaysAsWeek,
		handleSelectDate,
		handleGoNextMonth,
		handleGoPreviousMonth,
	}
}
