import React, { useContext } from "react"
import { Route, useParams, useRouteMatch } from "react-router-dom"
import { Calendar } from "./calender organism"
import { CalendarTitleHeader } from "./Calendar/CalendarTitleHeader"
import { presentationModeProjectIdProps } from "./calendarUtils"

export const WeekPlanningSchedule = ({ presentationMode = false, projectId }: presentationModeProjectIdProps) => {
	return (
		<div className={""}>
			<CalendarTitleHeader presentationMode={presentationMode} projectId={projectId} />
			<Calendar presentationMode={presentationMode} projectId={projectId} />
		</div>
	)
}
