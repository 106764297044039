import { format } from "date-fns"
import { SchemaActivity } from "models"
import CircleArrowDown from "presentationview/CircleArrowDown"
import CircleArrowUp from "presentationview/CircleArrowUp"
import { OneDayActivityCard } from "presentationview/OneDayActivityCard"
import { useState } from "react"
import { sv as svenska } from "date-fns/locale"
interface OneDayActivitesProps {
	day: Date
	presentationMode: boolean
	isSelected: boolean
	oneDayActivites: SchemaActivity[]
	projectId: string
}
const expandIcon = (expand, totalNumberOfActivities, maxItemsToShowBeforeExpand, setExpand) => {
	return (
		<div className="flex justify-center content-center" onClick={() => setExpand(!expand)}>
			{expand ? (
				<div className="pb-1">
					<CircleArrowUp circleColor="#fff" />
				</div>
			) : (
				<div className="flex justify-between">
					<div />
					<CircleArrowDown circleColor="#fff" />
					<p className="text-blue-600 text-sm">{"+" + (totalNumberOfActivities - maxItemsToShowBeforeExpand) + " aktiviter"}</p>
				</div>
			)}
		</div>
	)
}

export const OneDayActivites = ({ day, presentationMode, isSelected, oneDayActivites, projectId }: OneDayActivitesProps) => {
	const maxItemsToShowBeforeExpand = 3
	const expandable = oneDayActivites.length > maxItemsToShowBeforeExpand
	const [expand, setExpand] = useState<Boolean>(false)
	const dateDescription = format(day, "EEEE d LLLL", { locale: svenska })
	const baseColour = "bg-brand-whiteGray"
	const selectedDateColour = "bg-brand-blueSelected"
	return (
		<div className={"grid w-full " + (presentationMode ? "" : " max-h-36 ")}>
			<p className={(isSelected ? "text-brand-darkBlue" : "text-brand-black") + " pl-2 text-brand-darkBlue first-letter:capitalize text-left" + (isSelected ? " font-bold" : " font-light")}>
				{dateDescription}
			</p>
			<div
				className={
					(isSelected ? selectedDateColour : baseColour) + " overflow-visible justify-center space-y-1 grow mx-1 " + (expand ? " z-1 shadow-lg " : "") + (presentationMode ? " pb-1" : " mt-0.5")
				}>
				{oneDayActivites?.map((dayData, index) => {
					if (!presentationMode && index >= maxItemsToShowBeforeExpand && !expand) {
						return <></>
					}
					return <OneDayActivityCard key={dayData.eid} data={dayData} projectId={projectId} />
				})}

				{expandable && !presentationMode ? <>{expandIcon(expand, oneDayActivites.length, maxItemsToShowBeforeExpand, setExpand)}</> : <></>}
			</div>
		</div>
	)
}
