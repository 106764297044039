import { useState } from "react"
import { format } from "date-fns"
import { sv as svenska } from "date-fns/locale"
import { Link, useRouteMatch } from "react-router-dom"
import { SchemaActivity } from "models"
import { isSameWeek } from "presentationview/calendarUtils"
import { getRightColor } from "presentationview/schemaActivity"
import useMediaQuery from "presentationview/hooks/useMediaQuery"

interface IActivityCardProps {
	data: SchemaActivity
	projectId: string
	day: Date
}

export const MultipleDayActivityCard = ({ data, day, projectId }: IActivityCardProps) => {
	const { url } = useRouteMatch()
	const startDay = new Date(data.startDate)
	const endDay = new Date(data.endDate)
	const numberOfColumnsInCalender = useMediaQuery("(max-width: 1024px)") ? 5 : 7
	const [isStartDay] = useState<boolean>(format(startDay, "d M Y") === format(day, "d M Y") || day.getDay() === 1)
	let numberOfDays = isSameWeek(day, endDay)
		? Math.ceil(Math.abs(endDay.getTime() - day.getTime()) / (1000 * 3600 * 24))
		: day.getDay() === 0
		? 0
		: Math.abs(numberOfColumnsInCalender - day.getDay() + 1)

	const activityDateInfo = () => {
		return (
			<p className="w-full multiple-day-schema-acticvity-title">
				{format(new Date(data.startDate), "d", {
					locale: svenska,
				}) +
					"-" +
					format(new Date(data.endDate), "d LLLL", {
						locale: svenska,
					}) +
					"  " +
					data.title}
			</p>
		)
	}

	const basePrecentage = () => {
		return String(100 * (numberOfDays > 0 ? numberOfDays : 1) + "%")
	}
	const padding = () => {
		return String(0.5 * (numberOfDays > 0 ? numberOfDays - 1 : 0) - 0.125 + "rem")
	}
	return (
		<>
			{isStartDay && new Date(data.endDate) >= day ? (
				<div
					className={" bg-white my-0.5 relative flex  border-l-8  text-sm rounded  cursor-pointer h-4 "}
					style={{
						borderColor: getRightColor(data.schemaActivityType),
						width: `calc(${basePrecentage()} + ${padding()})`,
					}}>
					<Link key={data.title + data.startsAt + data.endsAt} className={"pl-2 flex justify-center text-center w-full"} to={`${url}/edit/${data.eid}`}>
						<div className={"inline-grid w-full"}>{activityDateInfo()}</div>
					</Link>
				</div>
			) : (
				<div
					className={"h-5  my-0.5 relative w-full"}
					style={{
						zIndex: -1,
					}}></div>
			)}
		</>
	)
}
