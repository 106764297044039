import { getWeek, isSameWeek } from "date-fns"
import { useScheduleActivities } from "presentationview/hooks/useScheduleActivities"
import { useScheduleGridLayout } from "presentationview/hooks/useScheduleGridLayout"
import { CalandarDayCard } from "../CalendarDayCard"

interface CalendarWeekProps {
	week: any
	isLastWeek: boolean
	projectId: string
	selectedDate?: Date
	presentationMode?: boolean
}
export const CalendarWeek = ({ week, isLastWeek, projectId, selectedDate = new Date(), presentationMode = false }: CalendarWeekProps) => {
	const { findOneDayAndMultipleDayEventsForDate } = useScheduleActivities(projectId)

	const { isBelow1024, getGridLayout } = useScheduleGridLayout()
	const weekNumber = (day, selectedDate) => {
		const wn = getWeek(day)
		const copy = new Date(selectedDate)

		return (
			<div className={"flex justify-center items-center text-center h-full " + (isSameWeek(copy, day) ? " bg-brand-darkBlue  text-white" : "bg-white ")}>
				<p className={"text-xl"}>{wn}</p>
			</div>
		)
	}

	const weekDifference = getWeek(week[0]) - getWeek(selectedDate)
	return (
		<div className={"grid grid-cols-19 "}>
			<div className={"col-span-1 " + (1 > weekDifference && weekDifference > 0 ? "mb-1" : "")}>{weekNumber(week[0], selectedDate)}</div>
			<div className="col-start-2 col-end-20 bg-brand-darkBlue">
				<ul className={"grid " + getGridLayout() + " gap-x-1 bg-brand-darkBlue " + (isSameWeek(week[0], selectedDate) ? "py-3" : weekDifference !== -1 && !isLastWeek ? "mb-1" : "")}>
					{week?.map((day, index) => {
						const [oneDayActivites, activitesOverMultipleDays] = findOneDayAndMultipleDayEventsForDate(day)

						if (isBelow1024) {
							if (day.getDay() === 6 || day.getDay() === 0) return <></>
						}

						return (
							<div key={day + index} className={"contents"}>
								<CalandarDayCard
									index={index}
									oneDayActivites={oneDayActivites}
									activitesOverMultipleDays={activitesOverMultipleDays}
									day={day}
									selectedDate={selectedDate}
									projectId={projectId}
									presentationMode={presentationMode}
								/>
							</div>
						)
					})}
				</ul>
			</div>
		</div>
	)
}
