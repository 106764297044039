import { GenericDataResponse, SchemaActivity } from "models"
import { useQuery } from "react-query"
import { useCommonQueries } from "./useCommonQueries"

type GenericSchemaActivityList = GenericDataResponse<SchemaActivity[]>

export const useActivities = (projectId: string) => {
	const { genericQueryFn } = useCommonQueries()
	return useQuery<GenericSchemaActivityList, Error, SchemaActivity[]>({
		queryKey: ["schedule-activity", projectId],
		queryFn: () =>
			genericQueryFn("useActivities", "schedule-activity", {
				projectId,
			}),
		select: response => response.data ?? [],
		refetchInterval: 60000,
	})
}
