import { format, startOfWeek } from "date-fns"
import { sv as svenska } from "date-fns/locale"
import { SchemaActivity } from "models"

export interface presentationModeProjectIdProps {
	presentationMode: boolean
	projectId: string
}
export interface projectIdProps {
	projectId: string
}
export const weekDayNames = [...Array.from(Array(7).keys())].map(i => svenska?.localize?.day(i))

export const formatMonthAndYear = (date = new Date()) => `${format(date, "LLLL", { locale: svenska })} ${date.getFullYear()}`

export const getWeek = (date: Date) => {
	const janFirst = new Date(date.getFullYear(), 0, 1)
	// Source: https://stackoverflow.com/a/27125580/3307678
	return Math.ceil(((date.getTime() - janFirst.getTime()) / 86400000 + janFirst.getDay() + 1) / 7)
}
export const isSameWeek = (dateA: Date, dateB: Date) => {
	return startOfWeek(dateA, { weekStartsOn: 1 }).toDateString() === startOfWeek(dateB, { weekStartsOn: 1 }).toDateString()
}
export const getDateOfISOWeek = (w: number, y: any) => {
	var simple = new Date(y, 0, 1 + (w - 1) * 7)
	var dow = simple.getDay()
	var ISOweekStart = simple
	if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
	else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
	return ISOweekStart
}

export const weekDayNamesStartingMonday = () => {
	let week: any[] = weekDayNames.slice()

	week.push(week.shift()!)

	return week
}

export const IsStartDayAndEndDayTheSame = (item: SchemaActivity) => {
	return item.startDate === item.endDate
}
