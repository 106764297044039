import { ActivityContext } from "presentationview/ActivityContext"
import { CalendarWeek } from "presentationview/Calendar/CalendarWeek"
import { presentationModeProjectIdProps, weekDayNamesStartingMonday } from "presentationview/calendarUtils"
import { useScheduleGridLayout } from "presentationview/hooks/useScheduleGridLayout"
import { useContext, useMemo } from "react"

import "./style.css"

export const Calendar = ({ presentationMode = false, projectId }: presentationModeProjectIdProps) => {
	const { isBelow1024, getGridLayout } = useScheduleGridLayout()
	const { scheduleDaysAsWeek } = useContext(ActivityContext)
	const weekDayNameColumnHeaders = () => {
		return (
			<div className="grid grid-cols-19">
				<div className="col-start-2 col-end-20">
					<ul className={"grid " + getGridLayout() + " mb-1 gap-x-1"}>
						{weekDayNamesStartingMonday().map(wd => {
							if (isBelow1024 && (wd === "lördag" || wd === "söndag")) {
								return <></>
							}
							return (
								<li key={wd} className="text-sm text-center font-medium capitalize">
									{wd}
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		)
	}
	const calendar = useMemo(() => {
		return (
			<>
				{scheduleDaysAsWeek?.map((week, weekIndex) => {
					return <CalendarWeek key={week + weekIndex} week={week} isLastWeek={weekIndex === scheduleDaysAsWeek.length - 1} projectId={projectId} presentationMode={presentationMode} />
				})}
			</>
		)
	}, [projectId, scheduleDaysAsWeek, presentationMode])

	return (
		<div className="main-weekPlanning">
			<div className="w-full">
				{/* {weekDayNameColumnHeaders()} */}
				{calendar}
			</div>
		</div>
	)
}
