import { BrowserRouter as Router } from "react-router-dom"

import { QueryClient, QueryClientProvider } from "react-query"
import { PresentationViewWrapper } from "components/presentationViewWrapper"
import { useToken } from "hooks/useToken"
import { PsvTvCode } from "components/PsvTvCode"
export const App = () => {
	const queryClient = new QueryClient()
	const { token, projectId, tvCode, timeLeft } = useToken()

	return (
		<Router>
			<QueryClientProvider client={queryClient}>
				<div className="app-container">
					{/* todo add cheeck on right thing in token */}
					<header className="w-full h-full text-center">
						<>{projectId !== "" ? <PresentationViewWrapper projectId={projectId} /> : <PsvTvCode tvCode={tvCode} timeLeft={timeLeft} token={token} />}</>
					</header>
				</div>
			</QueryClientProvider>
		</Router>
	)
}
