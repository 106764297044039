export const WastbyggLogo = ({ className = "w-6 h-6", bgFill = "#EDEDED" }) => {
	return (
		<svg id="Group_2954" data-name="Group 2954" xmlns="http://www.w3.org/2000/svg" width="610.126" height="76" viewBox="0 0 610.126 76">
			<defs>
				<clipPath id="clipPath">
					<rect id="Rectangle_1567" data-name="Rectangle 1567" width="610.126" height="76" fill="none" />
				</clipPath>
			</defs>
			<g id="Group_2953" data-name="Group 2953" transform="translate(0 0)" clipPath="url(#clipPath)">
				<path
					id="Path_1933"
					data-name="Path 1933"
					d="M72.312,9.171,61.221,39.255a1.233,1.233,0,0,1-2.348-.106L50.79,9.171H36.73L28.645,39.152a1.234,1.234,0,0,1-2.348.106L15.206,9.171H0L21.077,59.353h12.6L43.653,25.4h.213l9.981,33.951h12.6L87.52,9.171Z"
					transform="translate(0 4.305)"
					fill="#161615"
				/>
				<path
					id="Path_1934"
					data-name="Path 1934"
					d="M123.994,21.492a1.53,1.53,0,0,1-2.111.076,13.627,13.627,0,0,0-8.164-3.415,12.789,12.789,0,0,0-5.788.865,4.223,4.223,0,0,0-2.034,5.9c.912,1.452,3.1,2.333,4.811,2.843,6.871,2.039,14.8,3.663,18.967,10.272,2.276,3.607,1.859,10.146-.112,13.749-7.007,12.817-29.69,11.274-38.918,2.02L97.892,46.8a1.553,1.553,0,0,1,2.142.007,16.892,16.892,0,0,0,10.574,4.351,10.963,10.963,0,0,0,5.529-1.318,5.015,5.015,0,0,0,1.6-6.916,5.526,5.526,0,0,0-2.452-2.172,56.509,56.509,0,0,0-6.434-2.41c-6.245-1.988-12.562-3.122-15.057-8.834a14.7,14.7,0,0,1,5.332-17.826c4.173-2.727,9.62-3.463,14.564-3.377,6.674.116,12.8,2.151,17.306,6.165l-7,7.022"
					transform="translate(42.546 3.895)"
					fill="#161615"
				/>
				<path
					id="Path_1935"
					data-name="Path 1935"
					d="M147.979,21.06V59.352H135.858V21.06a1.541,1.541,0,0,0-1.541-1.541H120.142V9.17H163.7V19.519H149.521a1.542,1.542,0,0,0-1.541,1.541"
					transform="translate(56.391 4.304)"
					fill="#161615"
				/>
				<path
					id="Path_1936"
					data-name="Path 1936"
					d="M196.152,44.752a13.545,13.545,0,0,1-1.63,6.91,12.925,12.925,0,0,1-4.324,4.5,18.849,18.849,0,0,1-6.13,2.445,33.212,33.212,0,0,1-7.053.745H153.944V9.171h23.071a30.7,30.7,0,0,1,5.954.6,17.679,17.679,0,0,1,5.529,2.056,11.7,11.7,0,0,1,4.039,3.933,11.675,11.675,0,0,1,1.559,6.308,10.439,10.439,0,0,1-2.267,6.91,12.636,12.636,0,0,1-5.954,3.933v.143a13.793,13.793,0,0,1,4,1.311,11.589,11.589,0,0,1,3.262,2.446,11.457,11.457,0,0,1,2.2,3.472,11.687,11.687,0,0,1,.814,4.465M182.4,23.7a4.02,4.02,0,0,0-1.95-3.722,11.43,11.43,0,0,0-5.776-1.17h-7.5a1.542,1.542,0,0,0-1.541,1.541v8.807h9.605a8.773,8.773,0,0,0,5.279-1.383A4.739,4.739,0,0,0,182.4,23.7m1.63,19.916a4.431,4.431,0,0,0-2.339-4.323,13.706,13.706,0,0,0-6.237-1.2h-9.817V49.571h9.887a18.247,18.247,0,0,0,2.942-.248,9.32,9.32,0,0,0,2.765-.885,5.034,5.034,0,0,0,2.8-4.821"
					transform="translate(72.256 4.305)"
					fill="#161615"
				/>
				<path
					id="Path_1937"
					data-name="Path 1937"
					d="M289.84,59.854a45.2,45.2,0,0,1-10.738,1.2,31.135,31.135,0,0,1-11.092-1.915,25.586,25.586,0,0,1-8.718-5.387,24.56,24.56,0,0,1-5.706-8.328,27.442,27.442,0,0,1-2.056-10.809,27.494,27.494,0,0,1,2.091-10.914,24.259,24.259,0,0,1,5.778-8.328,25.565,25.565,0,0,1,8.647-5.279,30.493,30.493,0,0,1,10.7-1.844,32.894,32.894,0,0,1,11.057,1.807,22.863,22.863,0,0,1,8.293,4.855l-7.655,8.718a13.254,13.254,0,0,0-4.678-3.365,15.857,15.857,0,0,0-6.59-1.312,14.624,14.624,0,0,0-5.883,1.17,14.163,14.163,0,0,0-4.678,3.261,14.781,14.781,0,0,0-3.083,4.962,17.384,17.384,0,0,0-1.1,6.271,19.61,19.61,0,0,0,.992,6.38,13.705,13.705,0,0,0,2.942,5,13.357,13.357,0,0,0,4.82,3.261,17.242,17.242,0,0,0,6.556,1.17,24.515,24.515,0,0,0,4.039-.319,15.894,15.894,0,0,0,2.269-.547,1.846,1.846,0,0,0,1.275-1.762V41.472a1.537,1.537,0,0,0-1.541-1.535h-8.027v-9.78h20.7V56.736a38.53,38.53,0,0,1-8.612,3.118"
					transform="translate(118.06 3.874)"
					fill="#161615"
				/>
				<path
					id="Path_1938"
					data-name="Path 1938"
					d="M253.375,59.854a45.2,45.2,0,0,1-10.738,1.2,31.135,31.135,0,0,1-11.092-1.915,25.586,25.586,0,0,1-8.718-5.387,24.56,24.56,0,0,1-5.706-8.328,27.441,27.441,0,0,1-2.056-10.809,27.493,27.493,0,0,1,2.091-10.914,24.259,24.259,0,0,1,5.778-8.328,25.565,25.565,0,0,1,8.647-5.279,30.493,30.493,0,0,1,10.7-1.844,32.894,32.894,0,0,1,11.057,1.807,22.862,22.862,0,0,1,8.293,4.855l-7.655,8.718a13.254,13.254,0,0,0-4.678-3.365,15.856,15.856,0,0,0-6.59-1.312,14.624,14.624,0,0,0-5.883,1.17,14.164,14.164,0,0,0-4.678,3.261,14.782,14.782,0,0,0-3.083,4.962,17.384,17.384,0,0,0-1.1,6.271,19.649,19.649,0,0,0,.99,6.38,13.727,13.727,0,0,0,2.943,5,13.356,13.356,0,0,0,4.82,3.261,17.243,17.243,0,0,0,6.556,1.17,24.515,24.515,0,0,0,4.039-.319,15.76,15.76,0,0,0,2.273-.548A1.84,1.84,0,0,0,250.86,47.8V41.472a1.537,1.537,0,0,0-1.541-1.535h-8.027v-9.78h20.7V56.736a38.527,38.527,0,0,1-8.612,3.118"
					transform="translate(100.945 3.874)"
					fill="#161615"
				/>
				<path
					id="Path_1939"
					data-name="Path 1939"
					d="M93.71,59.353,89.8,49.5H70.331l-3.7,9.852H53.452L74.524,9.171h11.8l20.858,50.182ZM79.037,25.716,73.8,39.79H86.436L81.352,25.728a1.233,1.233,0,0,0-2.316-.012"
					transform="translate(25.089 4.305)"
					fill="#161615"
				/>
				<path
					id="Path_1940"
					data-name="Path 1940"
					d="M63.525,0H88.6A.971.971,0,0,1,89.61.924V8.73a.971.971,0,0,1-1.012.926H63.525a.972.972,0,0,1-1.012-.926V.924A.972.972,0,0,1,63.525,0"
					transform="translate(29.341 0)"
					fill="#161615"
				/>
				<path
					id="Path_1941"
					data-name="Path 1941"
					d="M232.573,9.171H218.4l-9.635,16.717a1.233,1.233,0,0,1-2.136,0L196.991,9.171H182.319l12.3,18.858c4.775,7.57,6.556,12.54,6.556,21.932v9.392h12.121V49.961c0-9.364,1.935-14.362,6.674-21.882Z"
					transform="translate(85.574 4.305)"
					fill="#161615"
				/>
				<rect id="Rectangle_1566" data-name="Rectangle 1566" width="3.306" height="74.847" transform="translate(439.43 1.153)" fill="#8c8b8b" />
				<path
					id="Path_1942"
					data-name="Path 1942"
					d="M340.375,12.78l-3.759,10.2a.418.418,0,0,1-.8-.035L333.081,12.78h-4.765l-2.74,10.161a.417.417,0,0,1-.795.037l-3.76-10.2h-5.153l7.143,17.008h4.269l3.384-11.507h.072l3.383,11.507h4.268l7.144-17.008Z"
					transform="translate(148.257 5.999)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1943"
					data-name="Path 1943"
					d="M358.955,16.957a.519.519,0,0,1-.716.026,4.612,4.612,0,0,0-2.767-1.158,4.323,4.323,0,0,0-1.962.294,1.43,1.43,0,0,0-.689,2,2.961,2.961,0,0,0,1.63.964c2.329.692,5.016,1.242,6.428,3.481a5.3,5.3,0,0,1-.037,4.661c-2.376,4.343-10.064,3.82-13.191.685l2.455-2.376a.529.529,0,0,1,.727,0,5.727,5.727,0,0,0,3.582,1.475,3.734,3.734,0,0,0,1.875-.447,1.7,1.7,0,0,0,.542-2.345,1.873,1.873,0,0,0-.83-.736,19.424,19.424,0,0,0-2.182-.817c-2.116-.673-4.257-1.058-5.1-2.993a4.983,4.983,0,0,1,1.809-6.042,8.842,8.842,0,0,1,4.936-1.145,8.946,8.946,0,0,1,5.866,2.089l-2.374,2.38"
					transform="translate(163.177 5.86)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1944"
					data-name="Path 1944"
					d="M367.581,16.809V29.786h-4.108V16.809a.523.523,0,0,0-.522-.523h-4.8V12.78h14.761v3.506h-4.8a.523.523,0,0,0-.523.523"
					transform="translate(168.102 5.999)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1945"
					data-name="Path 1945"
					d="M384.339,24.839a4.6,4.6,0,0,1-.551,2.342,4.405,4.405,0,0,1-1.466,1.525,6.413,6.413,0,0,1-2.078.829,11.313,11.313,0,0,1-2.391.251h-7.818V12.78h7.818a10.367,10.367,0,0,1,2.019.2,6.01,6.01,0,0,1,1.873.7,3.74,3.74,0,0,1,1.9,3.471,3.535,3.535,0,0,1-.768,2.342,4.279,4.279,0,0,1-2.017,1.333v.048a4.65,4.65,0,0,1,1.358.445,3.836,3.836,0,0,1,2.125,3.519M379.68,17.7a1.363,1.363,0,0,0-.66-1.261,3.87,3.87,0,0,0-1.959-.4h-2.541a.523.523,0,0,0-.523.523v2.984h3.256a2.97,2.97,0,0,0,1.79-.469,1.608,1.608,0,0,0,.636-1.381m.552,6.75a1.5,1.5,0,0,0-.792-1.465,4.651,4.651,0,0,0-2.114-.408H374v3.891h3.352a6.246,6.246,0,0,0,1-.084,3.136,3.136,0,0,0,.936-.3,1.7,1.7,0,0,0,.949-1.634"
					transform="translate(173.682 5.999)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1946"
					data-name="Path 1946"
					d="M417.392,29.957a15.327,15.327,0,0,1-3.64.408,10.534,10.534,0,0,1-3.759-.649,8.655,8.655,0,0,1-2.955-1.825,8.3,8.3,0,0,1-1.934-2.823,9.3,9.3,0,0,1-.7-3.663,9.311,9.311,0,0,1,.708-3.7,8.24,8.24,0,0,1,1.957-2.823,8.706,8.706,0,0,1,2.931-1.79,10.349,10.349,0,0,1,3.626-.624,11.149,11.149,0,0,1,3.748.613,7.745,7.745,0,0,1,2.811,1.646L417.6,17.682a4.492,4.492,0,0,0-1.585-1.142,5.392,5.392,0,0,0-2.233-.444,4.945,4.945,0,0,0-1.994.4,4.771,4.771,0,0,0-1.586,1.1,4.97,4.97,0,0,0-1.045,1.681,5.872,5.872,0,0,0-.373,2.126,6.636,6.636,0,0,0,.337,2.161,4.665,4.665,0,0,0,1,1.694,4.542,4.542,0,0,0,1.632,1.1,5.85,5.85,0,0,0,2.222.4,8.327,8.327,0,0,0,1.369-.109,5.407,5.407,0,0,0,.77-.185.626.626,0,0,0,.432-.6V23.727a.521.521,0,0,0-.523-.52H413.3V19.892h7.015V28.9a13.1,13.1,0,0,1-2.92,1.056"
					transform="translate(189.816 5.853)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1947"
					data-name="Path 1947"
					d="M404.6,29.957a15.327,15.327,0,0,1-3.64.408,10.533,10.533,0,0,1-3.759-.649,8.655,8.655,0,0,1-2.955-1.825,8.3,8.3,0,0,1-1.934-2.823,9.3,9.3,0,0,1-.7-3.663,9.311,9.311,0,0,1,.708-3.7,8.24,8.24,0,0,1,1.957-2.823,8.706,8.706,0,0,1,2.931-1.79,10.349,10.349,0,0,1,3.626-.624,11.15,11.15,0,0,1,3.748.613,7.745,7.745,0,0,1,2.811,1.646L404.8,17.682a4.492,4.492,0,0,0-1.585-1.142,5.392,5.392,0,0,0-2.234-.444,4.945,4.945,0,0,0-1.994.4,4.771,4.771,0,0,0-1.585,1.1,4.97,4.97,0,0,0-1.045,1.681,5.873,5.873,0,0,0-.373,2.126,6.637,6.637,0,0,0,.336,2.161,4.665,4.665,0,0,0,1,1.694,4.541,4.541,0,0,0,1.632,1.1,5.85,5.85,0,0,0,2.222.4,8.331,8.331,0,0,0,1.37-.109,5.633,5.633,0,0,0,.771-.185.625.625,0,0,0,.431-.6V23.727a.521.521,0,0,0-.523-.52h-2.72V19.892h7.015V28.9a13.1,13.1,0,0,1-2.92,1.056"
					transform="translate(183.812 5.853)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1948"
					data-name="Path 1948"
					d="M348.2,29.788l-1.325-3.34h-6.6l-1.252,3.34h-4.468l7.141-17.008h4l7.069,17.008Zm-4.974-11.4-1.777,4.77h4.285l-1.724-4.765a.418.418,0,0,0-.785,0"
					transform="translate(157.027 5.999)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1949"
					data-name="Path 1949"
					d="M337.964,9.175h8.5a.33.33,0,0,1,.344.313v2.646a.33.33,0,0,1-.344.313h-8.5a.329.329,0,0,1-.342-.313V9.488a.329.329,0,0,1,.342-.313"
					transform="translate(158.468 4.307)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1950"
					data-name="Path 1950"
					d="M397.11,12.78h-4.8l-3.265,5.666a.418.418,0,0,1-.724,0l-3.265-5.666h-4.972l4.167,6.392a11.929,11.929,0,0,1,2.223,7.432v3.184h4.107V26.6a11.982,11.982,0,0,1,2.263-7.416Z"
					transform="translate(178.396 5.999)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1951"
					data-name="Path 1951"
					d="M330.124,45.325a15.313,15.313,0,0,1-3.638.408,10.569,10.569,0,0,1-3.76-.648,8.7,8.7,0,0,1-2.955-1.826,8.324,8.324,0,0,1-1.934-2.823,10,10,0,0,1,.012-7.363,8.237,8.237,0,0,1,1.959-2.821,8.639,8.639,0,0,1,2.93-1.79,10.146,10.146,0,0,1,3.6-.626,11.361,11.361,0,0,1,3.778.613,7.735,7.735,0,0,1,2.809,1.646l-2.593,2.955a4.491,4.491,0,0,0-1.584-1.14,5.38,5.38,0,0,0-2.235-.445,4.945,4.945,0,0,0-1.994.4,4.809,4.809,0,0,0-1.585,1.1,5.016,5.016,0,0,0-1.045,1.681,5.9,5.9,0,0,0-.373,2.126,6.668,6.668,0,0,0,.337,2.161,4.633,4.633,0,0,0,1,1.693,4.513,4.513,0,0,0,1.634,1.106,5.84,5.84,0,0,0,2.222.4,8.315,8.315,0,0,0,1.368-.107,5.615,5.615,0,0,0,.77-.185.627.627,0,0,0,.432-.6V39.1a.521.521,0,0,0-.523-.52h-2.72V35.26h7.013v9.009a13.029,13.029,0,0,1-2.918,1.056"
					transform="translate(148.855 13.066)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1952"
					data-name="Path 1952"
					d="M340.549,45.156,337.064,38.8a.734.734,0,0,0-.642-.379H334.4v6.734H330.39V28.148h7.541a11.29,11.29,0,0,1,2.389.253,6.219,6.219,0,0,1,2.091.852,4.467,4.467,0,0,1,1.477,1.587,5.377,5.377,0,0,1,.552,2.555,4.27,4.27,0,0,1-.936,2.817,5.118,5.118,0,0,1-2.595,1.681l4.445,7.263Zm-.137-11.689a2.078,2.078,0,0,0-.285-1.079,1.717,1.717,0,0,0-.641-.577,2.952,2.952,0,0,0-.9-.275,6.567,6.567,0,0,0-.954-.072H334.9a.522.522,0,0,0-.523.522v3.456h3.011a7.124,7.124,0,0,0,1.08-.062,3.426,3.426,0,0,0,.976-.3,1.756,1.756,0,0,0,.68-.624,1.625,1.625,0,0,0,.288-.989"
					transform="translate(155.074 13.212)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1953"
					data-name="Path 1953"
					d="M359.79,36.726a9.483,9.483,0,0,1-.7,3.687,8.4,8.4,0,0,1-1.934,2.859,8.18,8.18,0,0,1-2.839,1.838,10.259,10.259,0,0,1-7.176,0,8.2,8.2,0,0,1-2.827-1.838,8.4,8.4,0,0,1-1.934-2.859,9.483,9.483,0,0,1-.7-3.687,9.376,9.376,0,0,1,.7-3.688,8.081,8.081,0,0,1,1.934-2.8,8.167,8.167,0,0,1,2.827-1.778,10.621,10.621,0,0,1,7.176,0,8.361,8.361,0,0,1,4.772,4.576,9.376,9.376,0,0,1,.7,3.688m-4.4,0a5.8,5.8,0,0,0-.309-2.066,4.825,4.825,0,0,0-.943-1.646,4.385,4.385,0,0,0-1.486-1.081,4.641,4.641,0,0,0-1.929-.4,4.475,4.475,0,0,0-4.346,3.122,5.972,5.972,0,0,0-.3,2.066,6.05,6.05,0,0,0,.309,2.125,4.934,4.934,0,0,0,.943,1.671,4.339,4.339,0,0,0,1.474,1.092,4.832,4.832,0,0,0,3.835,0,4.454,4.454,0,0,0,1.486-1.092,4.809,4.809,0,0,0,.955-1.671,6.047,6.047,0,0,0,.309-2.125"
					transform="translate(160.376 13.066)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1954"
					data-name="Path 1954"
					d="M370.649,41.54a6.086,6.086,0,0,1-1.477,2.161,6.786,6.786,0,0,1-2.319,1.4,10.585,10.585,0,0,1-6.464,0,6.551,6.551,0,0,1-2.282-1.4,6.134,6.134,0,0,1-1.441-2.161,7.5,7.5,0,0,1-.5-2.8V28.148h4.082V38.4a4.715,4.715,0,0,0,.181,1.321,3.379,3.379,0,0,0,.552,1.117,2.58,2.58,0,0,0,.986.782,5.088,5.088,0,0,0,3.375,0,2.7,2.7,0,0,0,1-.782,3.121,3.121,0,0,0,.552-1.117,5.069,5.069,0,0,0,.167-1.321V28.148h4.107V38.742a7.326,7.326,0,0,1-.516,2.8"
					transform="translate(167.171 13.212)"
					fill="#8c8b8b"
				/>
				<path
					id="Path_1955"
					data-name="Path 1955"
					d="M383.2,33.385a5.229,5.229,0,0,1-.552,2.51,4.531,4.531,0,0,1-1.49,1.646,6.555,6.555,0,0,1-2.161.912,11.057,11.057,0,0,1-2.545.289h-3.437v6.412h-4.108V28.148h7.641a11.761,11.761,0,0,1,2.607.276,6.15,6.15,0,0,1,2.113.889,4.315,4.315,0,0,1,1.418,1.609,5.316,5.316,0,0,1,.516,2.463m-4.107.024a1.847,1.847,0,0,0-.239-.984,1.68,1.68,0,0,0-.65-.6,2.767,2.767,0,0,0-.926-.289,7.656,7.656,0,0,0-1.068-.072h-2.673a.522.522,0,0,0-.523.523v3.488h3.124a6.247,6.247,0,0,0,1.1-.1,2.983,2.983,0,0,0,.949-.336,1.824,1.824,0,0,0,.661-.638,1.9,1.9,0,0,0,.239-1"
					transform="translate(173.153 13.212)"
					fill="#8c8b8b"
				/>
			</g>
		</svg>
	)
}
