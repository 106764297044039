import React, { useEffect } from "react"
import { useState } from "react"
import "./style.css"
import { format, startOfWeek } from "date-fns"
import { OneDayActivites } from "./OneDayActivities"
import { SchemaActivity } from "models"
import { MultipleDayActivityCard } from "../MultiDayActivityCard"
interface CalandarDayCardProps {
	oneDayActivites: SchemaActivity[]
	activitesOverMultipleDays: SchemaActivity[]
	day: Date
	index: number
	selectedDate: Date
	projectId: string
	presentationMode?: boolean
}
export const CalandarDayCard = ({ oneDayActivites = [], activitesOverMultipleDays = [], day, index, selectedDate, projectId, presentationMode = false }: CalandarDayCardProps) => {
	const sortedActivitesOverMultipleDays = activitesOverMultipleDays.sort((a, b) => {
		let aStartDate = new Date(a.startDate)
		let bStartDate = new Date(b.startDate)
		let aEndDate = new Date(a.endDate)
		let bEndDate = new Date(b.endDate)
		const weekStart = startOfWeek(day, { weekStartsOn: 1 })
		aStartDate = weekStart > aStartDate ? weekStart : aStartDate
		bStartDate = weekStart > bStartDate ? weekStart : bStartDate

		if (format(aStartDate, "d M Y") === format(bStartDate, "d M Y")) {
			if (format(bEndDate, "d M Y") > format(aEndDate, "d M Y")) {
				return -1
			}
			if (format(bEndDate, "d M Y") < format(aEndDate, "d M Y")) {
				return 1
			}
			return 0
		}

		if (bStartDate > aStartDate) {
			return 1
		}
		if (bStartDate < aStartDate) {
			return -1
		}
		return 0
	})

	const baseColour = "bg-brand-whiteGray"
	const selectedDateColour = "bg-brand-blueSelected"
	const [isSelected, setIsSelected] = useState<boolean>(new Date(day).toDateString() === new Date(selectedDate).toDateString())

	useEffect(() => {
		setIsSelected(new Date(day).toDateString() === new Date(selectedDate).toDateString())
	}, [selectedDate, day])
	const cardContent = () => {
		return (
			<div className="grid grid-col-1 content-between h-full  ">
				<OneDayActivites day={day} presentationMode={presentationMode} isSelected={isSelected} oneDayActivites={oneDayActivites} projectId={projectId} />
				<div className="grid w-full ml-1 pr-1  ">
					{sortedActivitesOverMultipleDays?.map((dayData, index) => {
						return <MultipleDayActivityCard key={day + "-" + index} data={dayData} day={day} projectId={projectId} />
					})}
				</div>
			</div>
		)
	}
	return (
		<li key={day + "-" + index} className={"weekPlanningDate-box " + (presentationMode ? " presentationMode " : " ") + (isSelected ? selectedDateColour : baseColour) + " cursor-pointer "}>
			{cardContent()}
		</li>
	)
}
