import React, { ErrorInfo } from "react"

interface IErrorBoundryProps {
	children: React.ReactNode | React.ReactNodeArray
	errorClassName?: string
	customErrorText?: string
	onBack?: () => void
}

interface IErrorBoundryState {
	hasError: boolean
	error?: Error
}

class ErrorBoundry extends React.Component<IErrorBoundryProps, IErrorBoundryState> {
	constructor(props: IErrorBoundryProps) {
		super(props)
		this.state = {
			hasError: false,
		}
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.log("Cought error in render", error)
		console.log("errorInfo", errorInfo)

		this.setState({
			hasError: true,
			error: error,
		})
	}

	render(): React.ReactNode {
		if (this.state.hasError)
			return (
				<div className={this.props.errorClassName}>
					<h2 className="text-lg font-bold mb-2 text-red-600">{this.props.customErrorText ?? "Något gick fel när innehållet skulle visas."}</h2>

					<p>Försök igen senare.</p>

					{this.props.onBack && (
						<div className="mt-2" onClick={this.props.onBack}>
							Tillbaka{" "}
						</div>
					)}
				</div>
			)

		return this.props.children
	}
}

export default ErrorBoundry
