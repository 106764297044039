import { CountdownTimer } from "./CountdownTimer"
import { WastbyggLogo } from "./WastbyggLogo"

export const PsvTvCode = ({ tvCode, timeLeft, token }) => {
	return (
		<div className="grid  gap-y-2">
			<div className="flex w-ful justify-center gap-x-2">
				<WastbyggLogo />
			</div>
			<h3 className="text-3xl">PSV-TV kod: {tvCode}</h3>
			{!token && timeLeft && <CountdownTimer minutes={timeLeft.minutes} seconds={timeLeft.seconds} />}
		</div>
	)
}
