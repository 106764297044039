import { getRightColor } from "presentationview/schemaActivity"

export const CalendarEventLabels = () => {
	const labels = [
		["Möten", "Ronder"],
		["Besiktningar", "Leveranser"],
		["Maskiner", "Övrigt"],
	]

	return (
		<div className="flex gap-x-8">
			{labels.map((titleColumn, index) => {
				return (
					<div key={"eventLabels " + index} className="grid gap-y-2  justify-center">
						{titleColumn.map(title => {
							return (
								<div key={title} className="flex gap-x-1">
									<div
										className="w-5 h-5"
										style={{
											backgroundColor: getRightColor(title),
										}}></div>
									{title}
								</div>
							)
						})}
					</div>
				)
			})}
		</div>
	)
}
