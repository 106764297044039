import { ActivityContext } from "presentationview/ActivityContext"
import { WeekPlanningSchedule } from "presentationview/weeklySchedule"
import ErrorBoundry from "presentationview/ErrorBoundry"
import { useCalendar } from "presentationview/hooks/useCalendar"
import { projectIdProps } from "presentationview/calendarUtils"

export const PresentationViewWrapper = ({ projectId }: projectIdProps) => {
	const { loading: monthLoading, currentDate, weeklyScheduleDays, weeklyScheduleWeekNumbers, monthlyScheduleDays, monthlyScheduleWeekNumbers, selectedDate, scheduleDaysAsWeek } = useCalendar()

	return (
		<ErrorBoundry>
			<ActivityContext.Provider
				value={{
					monthLoading,
					weeklyScheduleDays,
					weeklyScheduleWeekNumbers,
					monthlyScheduleDays,
					monthlyScheduleWeekNumbers,
					scheduleDaysAsWeek,
					currentDate,
					selectedDate,
				}}>
				{projectId && projectId !== "" && <WeekPlanningSchedule presentationMode={true} projectId={projectId} />}
			</ActivityContext.Provider>
		</ErrorBoundry>
	)
}
