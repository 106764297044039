import classNames from 'classnames';
export const ActivityStatus = ({ value, className }) => {
  switch (value) {
    case 'DONE':
      return (
        <div className={classNames(className)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g
              id="Group_2253"
              data-name="Group 2253"
              transform="translate(-9 -5)"
            >
              <circle
                id="Ellipse_516"
                data-name="Ellipse 516"
                cx="12"
                cy="12"
                r="12"
                transform="translate(9 5)"
                fill="#57ac39"
              />
              <path
                id="Path_1565"
                data-name="Path 1565"
                d="M-5733.967-10847.582l3.039,2.977,5.689-5.735"
                transform="translate(5750.768 10864.721)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>
      );
    default:
    case 'NOT_DONE':
      return (
        <div className={classNames(className)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g
              id="Group_2253"
              data-name="Group 2253"
              transform="translate(-9 -5)"
            >
              <circle
                id="Ellipse_516"
                data-name="Ellipse 516"
                cx="12"
                cy="12"
                r="12"
                transform="translate(9 5)"
                fill="#EBECEC"
              />
            </g>
          </svg>
        </div>
      );
    case 'NOT_RELEVANT':
      return (
        <div className={classNames(className)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g
              id="Group_2253"
              data-name="Group 2253"
              transform="translate(-9 -5)"
            >
              <circle
                id="Ellipse_516"
                data-name="Ellipse 516"
                cx="12"
                cy="12"
                r="12"
                transform="translate(9 5)"
                fill="#184F62"
              />
              <path
                id="Path_1565"
                data-name="Path 1565"
                d="M-5733.967-10847.582l3.039,2.977,5.689-5.735"
                transform="translate(5750.768 10864.721)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>
      );
    case 'LATE':
      return (
        <div className={classNames(className)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g
              id="Group_2253"
              data-name="Group 2253"
              transform="translate(-9 -5)"
            >
              <circle
                id="Ellipse_516"
                data-name="Ellipse 516"
                cx="12"
                cy="12"
                r="12"
                transform="translate(9 5)"
                fill="#f36668"
              />
              <g
                id="Group_2296"
                data-name="Group 2296"
                transform="translate(14005.591 8823.979)"
              >
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M-24.048-48.1a5.543,5.543,0,0,0,5.543-5.543,5.543,5.543,0,0,0-5.543-5.543,5.543,5.543,0,0,0-5.543,5.543A5.543,5.543,0,0,0-24.048-48.1Z"
                  transform="translate(-13960.543 -8753.318)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="1"
                  strokeDasharray="0.1 1.5"
                />
                <path
                  id="Path_1573"
                  data-name="Path 1573"
                  d="M-24.048-59.182a5.543,5.543,0,0,1,5.543,5.543A5.543,5.543,0,0,1-24.048-48.1a5.628,5.628,0,0,1-5.122-3.42,5.235,5.235,0,0,1-.421-2.123,5.743,5.743,0,0,1,.312-1.8"
                  transform="translate(-13960.543 -8753.318)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_1574"
                  data-name="Path 1574"
                  d="M11.755,11.311l3.152,2.265A.954.954,0,1,1,13.8,15.128a.921.921,0,0,1-.222-.222l-2.265-3.152a.318.318,0,0,1,.444-.443Z"
                  transform="translate(-13998.683 -8821.551)"
                  fill="#fff"
                />
                <line
                  id="Line_288"
                  data-name="Line 288"
                  y2="1.584"
                  transform="translate(-13984.591 -8812.5)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </div>
      );
  }
};
